import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import Layout from "../components/shared/layout"
const Redirect301Template = ({ pageContext }) => {
  const redirectUrl = pageContext.redirectUrl
  useEffect(() => {
    navigate(redirectUrl)
  }, [redirectUrl])
  return (
    <Layout>
      <h1>301 Redirect</h1>
    </Layout>
  )
}
export default Redirect301Template
